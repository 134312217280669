<template>
  <div>
    <h1 class="font-bold text-xl mb-6">My Availability</h1>
    <div class="flex flex-wrap -mx-2">
      <div class="w-full lg:w-1/2 px-2">
        <h2 class="text-lg font-bold mb-3">Capacity</h2>
        <p class="mb-6">Please select the number of interviews you would like to conduct per week, along with a tolerance of the number of additional interviews you could conduct during busy periods.</p>

        <loader v-if="loadingCapacity" />
        <div v-else>
          <select-input label="I would like:" :options="capacityOptions" v-model="capacityForm.max_interviews_per_week" :error="errorBag.get('max_interviews_per_week')" />
          <select-input label="Tolerance:" :options="toleranceOptions" v-model="capacityForm.tolerance" :error="errorBag.get('tolerance')" />
          <checkbox-input label="Send automated notifications to the vendors when I schedule and complete work" v-model="capacityForm.send_vendor_notifications" :error="errorBag.get('send_vendor_notifications')" />

          <div class="flex justify-end">
            <primary-btn
              :loading="updatingCapacity"
              @click="saveCapacity"
            >
              Save
            </primary-btn>
          </div>
        </div>
      </div>
      <div class="w-full lg:w-1/2 px-2">
        <h2 class="text-lg font-bold mb-3">Holiday</h2>
        <p class="mb-6">Please schedule in any holidays you have, so that we can distribute the work accordingly.
          The dates you enter below are for days when you will not receive work.
          If you only book off the days you are away,
          you may receive a request the day before which you are unable to accommodate,
          so please bear this in mind when booking your holiday.
          Thank you.
        </p>
        <loader v-if="loadingHoliday" />
        <div v-else>
          <div class="bg-gray-200 p-3 rounded mb-6">
            <h3 class="font-bold mb-3">Book Holiday</h3>

            <div class="flex flex-wrap -mx-2">
              <div class="w-full md:w-1/2 px-2">
                <date-picker v-model="holidayBookingForm.start_date" label="Start Date" placeholder="Please Select" :min-date="minStartDate" :max-date="holidayBookingForm.end_date" :error="errorBag.get('start_date')" />
              </div>
              <div class="w-full md:w-1/2 px-2">
                <date-picker v-model="holidayBookingForm.end_date" label="End Date" placeholder="Please Select" :min-date="holidayBookingForm.start_date" :error="errorBag.get('end_date')" />
              </div>
            </div>

            <div class="flex justify-end">
              <primary-btn
                :loading="bookingHoliday"
                @click="bookHoliday"
              >
                Book Holiday
              </primary-btn>
            </div>
          </div>

          <div v-if="holidays.length === 0" class="italic">
            <i class="fas fa-info-circle"></i> No holiday booked.
          </div>
          <table v-else class="w-full">
            <thead class="text-left">
              <tr>
                <th>Start Date</th>
                <th>End Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="holiday in holidays" :key="holiday.id">
                <td>{{ holiday.start_date | date }}</td>
                <td>{{ holiday.end_date | date }}</td>
                <td class="text-right">
                  <modal>
                    <template v-slot:trigger>
                      Cancel
                    </template>
                    <p class="font-bold mb-3">Are you sure you want to cancel this holiday?</p>
                    <p class="mb-6">{{ holiday.start_date | date }} - {{ holiday.end_date | date }}</p>

                    <div class="flex justify-end">
                      <primary-btn
                        :loading="cancellingHoliday"
                        @click="cancelHoliday(holiday)"
                      >
                        Cancel Holiday
                      </primary-btn>
                    </div>
                  </modal>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/http";
import moment from 'moment'
import ErrorBag from "@/ErrorBag";
import SelectInput from "@/components/SelectInputComponent";
import CheckboxInput from "@/components/CheckboxInputComponent";
import DatePicker from "@/components/DatePickerComponent";
import Modal from "@/components/ModalComponent";
import PrimaryBtn from "@/components/PrimaryBtn";
import Loader from "@/components/Loader";
import { mapMutations } from 'vuex';

export default {
  components: {
    SelectInput,
    CheckboxInput,
    PrimaryBtn,
    DatePicker,
    Loader,
    Modal
  },
  data() {
    return {
      capacityOptions: [
        {name: 'Up to 2 interviews per week', id: 2},
        {name: 'Up to 5 interviews per week', id: 5},
        {name: 'Up to 10 interviews per week', id: 10},
        {name: 'Up to 15 interviews per week', id: 15},
        {name: '15+ interviews per week', id: null}
      ],
      toleranceOptions: [
        {name: 'No extra capacity', id: 0},
        {name: '+1 extra if required', id: 1},
        {name: '+2 extra if required', id: 2},
        {name: '+3 extra if required', id: 3},
        {name: '+4 extra if required', id: 4}
      ],
      errorBag: new ErrorBag(),
      loadingCapacity: true,
      loadingHoliday: true,
      updatingCapacity: false,
      bookingHoliday: false,
      cancellingHoliday: false,
      capacityForm: {
        max_interviews_per_week: null,
        tolerance: null,
        move_reason_blacklist: []
      },
      holidayBookingForm: {
        start_date: null,
        end_date: null
      },
      holidays: []
    }
  },
  created() {
    this.getCapacity()
    this.getHoliday()
  },
  computed: {
    minStartDate() {
      return moment().format('YYYY-MM-DD')
    }
  },
  methods: {
    ...mapMutations(['setSuccessMessage']),
    getCapacity() {
      return api.get("/auth-user").then((response) => {
        this.capacityForm = response.data.other;
        this.loadingCapacity = false;
      });
    },
    getHoliday() {
      return api.get("/interviewer/holiday").then((response) => {
        this.holidays = response.data;
        this.loadingHoliday = false;
      });
    },
    saveCapacity() {
      this.errorBag.clear()
      this.updatingCapacity = true
      return api.patch("/interviewer/capacity", this.capacityForm).then((response) => {
        this.holidays = response.data;
        this.updatingCapacity = false;
        this.setSuccessMessage('Your work capacity has been updated.');
      }, (errors) => {
        this.errorBag.store(errors.response.data);
        this.updatingCapacity = false;
      });
    },
    bookHoliday() {
      this.errorBag.clear()
      this.bookingHoliday = true
      return api.post("/interviewer/holiday", this.holidayBookingForm).then((response) => {
        this.holidays.push(response.data);
        this.bookingHoliday = false;
        this.setSuccessMessage('Holiday booked.');
      }, (errors) => {
        this.errorBag.store(errors.response.data);
        this.bookingHoliday = false;
      });
    },
    cancelHoliday(holiday) {
      this.cancellingHoliday = true

      return api.delete(`/interviewer/holiday/${holiday.id}`).then(() => {
        this.holidays.splice(this.holidays.indexOf(holiday), 1);
        this.cancellingHoliday = false;
        this.setSuccessMessage('Holiday cancelled.');
      });
    }
  }
}
</script>
